import React from 'react';
import PropTypes from 'prop-types';

import TeaseLink from '../Link';

import styles from './Tease.module.scss';

const Tease = ({ sticky, title, aside, link, externalLink, tag: TagWrapper }) => {
  const markup = (
    <article className={`${styles.wrapper} ${sticky ? styles.sticky : ''}`}>
      <div className={styles.source__details}>{aside}</div>
      <TeaseLink url={link} external={externalLink}>
        <div className={styles.tease__content}>
          <h2 className={styles.tease__title}>{title}</h2>
        </div>
      </TeaseLink>
      <hr className={styles.top__border} />
    </article>
  );

  return TagWrapper ? <TagWrapper>{markup}</TagWrapper> : markup;
};

Tease.defaultProps = {
  aside: null,
  link: null,
  externalLink: false,
  tag: null,
};

Tease.propTypes = {
  title: PropTypes.string.isRequired,
  aside: PropTypes.node,
  link: PropTypes.string,
  externalLink: PropTypes.bool,
  tag: PropTypes.string,
};

export default Tease;
