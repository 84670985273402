import React from 'react';
import PropTypes from 'prop-types';
import { Pagination } from 'semantic-ui-react';

import Arrow from '../../svgs/Arrow';

import styles from './NumberedPagination.module.scss';

import { className, useMediaQuery } from '../../utils';

const NumberedPagination = ({ numPages, currentPage, onPageChange }) => {
  const isMobile = useMediaQuery(768);

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          {numPages > 1 && (
            <Pagination
              activePage={currentPage}
              ellipsisItem={{ content: <span>...</span>, icon: true }}
              firstItem={null}
              lastItem={null}
              prevItem={{
                content: (
                  <span className={styles.text}>
                    <div {...className(styles.arrow, styles.arrowLeft)}>
                      <Arrow />
                    </div>
                    <span className={styles.arrowLabel}>Previous</span>
                  </span>
                ),
                icon: false,
                disabled: currentPage === 1,
              }}
              nextItem={{
                content: (
                  <span className={styles.text}>
                    <span className={styles.arrowLabel}>Next</span>
                    <div className={styles.arrow}>
                      <Arrow />
                    </div>
                  </span>
                ),
                icon: false,
                disabled: currentPage === numPages,
              }}
              totalPages={numPages}
              onPageChange={onPageChange}
              siblingRange={isMobile ? 0 : 1}
            />
          )}
        </div>
      </div>
    </div>
  );
};

NumberedPagination.propTypes = {
  numPages: PropTypes.number,
  currentPage: PropTypes.number,
  onPageChange: PropTypes.func.isRequired,
};

export default NumberedPagination;
