import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';
import moment from 'moment';

import { className } from '../../utils';
import linkResolver from '../../utils/linkResolver';

import Image from '../Image';
import Tease from '../Archive/Tease';

import styles from './ArticleTease.module.scss';

const ArticleLink = ({ url, isExternal, children }) => {
  if (!url) {
    return children;
  }

  if (!isExternal) {
    return <Link to={url}>{children}</Link>;
  }

  return <a href={url}>{children}</a>;
};

ArticleLink.propTypes = {
  url: PropTypes.string,
  isExternal: PropTypes.bool,
  children: PropTypes.node,
};

const ArticleTeaser = ({ article }) => {
  const {
    featured_image,
    featured_imageSharp,
    link,
    publishDate,
    source,
    sourceAuthor,
    source_logo_image,
    source_logo_imageSharp,
    title,
    sticky,
    _meta: { tags },
  } = article;

  const isInternal = link === null || !link.url ? true : false;

  // If the article is internal, get the link to the page. Otherwise, check to make sure that the
  // external link is set, and get that url.
  const url = isInternal ? linkResolver(article) : link ? linkResolver(link) : null;

  const aside = (
    <>
      {(featured_image || featured_imageSharp) && (
        <div className={styles.featured__image__wrapper}>
          <ArticleLink url={url} isExternal={!isInternal}>
            <Image
              image={featured_image}
              sharpImage={featured_imageSharp}
              className={styles.featured__image}
            />
          </ArticleLink>
        </div>
      )}
      {(source_logo_image || source_logo_imageSharp) && (
        <ArticleLink url={url} isExternal={!isInternal}>
          <Image
            image={source_logo_image}
            sharpImage={source_logo_imageSharp}
            alt={source}
            className={styles.source__logo}
          />
        </ArticleLink>
      )}
      <p className={styles.source__info}>
        <span className={styles.source__info__text}>{sourceAuthor}</span>
        <span className={styles.source__info__mobile}>,</span>
        {` `}
        <span className={styles.source__info__text}>
          {moment(publishDate).format('MM.DD.YYYY')}
        </span>
      </p>
    </>
  );

  return (
    <Tease
      sticky={sticky}
      aside={aside}
      title={RichText.asText(title)}
      link={url}
      externalLink={!isInternal}
      tag="li"
    />
  );
};

ArticleTeaser.propTypes = {
  article: PropTypes.shape({
    featured_image: PropTypes.object,
    featured_imageSharp: PropTypes.object,
    link: PropTypes.object,
    publishDate: PropTypes.string,
    source: PropTypes.string,
    sourceAuthor: PropTypes.string,
    source_logo_image: PropTypes.object,
    source_logo_imageSharp: PropTypes.object,
    tease: PropTypes.array,
    title: PropTypes.array.isRequired,
    sticky: PropTypes.bool,
    _meta: PropTypes.shape({
      tags: PropTypes.array,
    }),
  }),
};

export default ArticleTeaser;
