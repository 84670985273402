import React from 'react';
import PropTypes from 'prop-types';
import { className } from '../../../utils';

import styles from './List.module.scss';

const ArchiveList = ({ className: customClassName, children }) => (
  <ul {...className(styles.list, customClassName)}>{children}</ul>
);

ArchiveList.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default ArchiveList;
