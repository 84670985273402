import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import { className } from '../../../utils';

import ButtonCircle from '../../../svgs/ButtonCircle';
import Arrow from '../../../svgs/Arrow';

import styles from './Link.module.scss';

const TeaseLink = ({ url, external, className: extraClasses, children }) => {
  if (!url) {
    return <div {...className(styles.tease__wrapper, extraClasses)}>{children}</div>;
  }

  const LinkWrapper = ({ children }) =>
    external ? (
      <a href={url} {...className(styles.tease__wrapper, extraClasses)}>
        {children}
      </a>
    ) : (
      <Link to={url} {...className(styles.tease__wrapper, extraClasses)}>
        {children}
      </Link>
    );

  LinkWrapper.propTypes = {
    innerChildren: PropTypes.node,
  };

  return (
    <LinkWrapper>
      {children}

      <div className={styles.graphic__wrapper}>
        <div className={styles.graphic}>
          <ButtonCircle className={styles.circle__svg} />
          <Arrow className={styles.arrow__svg} />
        </div>
      </div>
    </LinkWrapper>
  );
};

TeaseLink.defaultProps = {
  url: null,
  external: false,
  className: null,
  children: null,
};

TeaseLink.propTypes = {
  url: PropTypes.string,
  external: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default TeaseLink;
