import React from 'react';
import PropTypes from 'prop-types';
import { graphql, navigate } from 'gatsby';

import SEO from '../components/SEO';
import Header from '../components/BasicPage/Header';
import Wrapper from '../components/Wrapper';
import ArchiveList from '../components/Archive/List';
import ArticleTease from '../components/ArticleTease';
import Footer, { footerCtaFragment } from '../components/Footer';
import { linkFragmentQuery } from './page';

import NumberedPagination from '../components/NumberedPagination';

const NewsAndMediaListing = ({ data, location, pageContext }) => {
  const stickyArticles = data.prismic.stickyPress.edges;
  const articles = data.prismic.allPresss.edges;
  const pressArchive = data.prismic.pressArchive.edges.slice(0, 1).pop();

  const { seo } = pressArchive.node;

  const lastChar = Number.parseInt(pageContext.slug.slice(-1));
  const currentPage = isNaN(lastChar) ? 1 : lastChar || 1;

  return (
    <>
      <SEO
        title="Press"
        description={seo && seo.description ? seo.description : undefined}
        image={seo && seo.image ? seo.image : undefined}
        href={location.href}
      />

      {pressArchive && (
        <Header
          image={pressArchive.node.header_image}
          sharpImage={pressArchive.node.header_imageSharp}
          overline={pressArchive.node.breadcrumb_title}
          title={pressArchive.node.title}
        />
      )}

      <Wrapper>
        {articles.length > 0 ? (
          <ArchiveList>
            {/* Render sticky article(s) */}
            {stickyArticles.map(({ node: article }) => {
              {
                /* Only render sticky article(s) on Page 1 */
              }
              if (currentPage === 1) {
                return <ArticleTease key={article._meta.id} article={article} />;
              }
            })}

            {/*
              Render the rest of the articles. Only render sticky articles here
              if we're not on Page 1.
            */}
            {articles.map(({ node: article }) => {
              if (currentPage !== 1 || !article.sticky) {
                return <ArticleTease key={article._meta.id} article={article} />;
              }
            })}
          </ArchiveList>
        ) : (
          <p>No articles</p>
        )}
      </Wrapper>

      <NumberedPagination
        numPages={pageContext.numPages}
        currentPage={currentPage}
        onPageChange={(e, data) => {
          // eslint-disable-next-line react/prop-types
          if (data.activePage === 1) {
            navigate('/news-and-media');
            return;
          }

          // eslint-disable-next-line react/prop-types
          navigate(`/news-and-media/${data.activePage}`);
        }}
      />

      <Footer cta={pressArchive && pressArchive.node.cta} />
    </>
  );
};

NewsAndMediaListing.propTypes = {
  data: PropTypes.shape({
    prismic: PropTypes.shape({
      stickyPress: PropTypes.shape({
        edges: PropTypes.array,
      }),
      allPresss: PropTypes.shape({
        edges: PropTypes.array,
      }),
      pressArchive: PropTypes.shape({
        edges: PropTypes.array,
      }),
    }),
  }),
  location: PropTypes.shape({
    href: PropTypes.string,
  }),
  pageContext: PropTypes.shape({
    numPages: PropTypes.number,
    slug: PropTypes.string,
  }),
};

NewsAndMediaListing.fragments = [footerCtaFragment, linkFragmentQuery];

export const query = graphql`
  fragment newsAndMediaFields on PRISMIC_Press {
    sticky
    featured_image
    featured_imageSharp {
      ...FeaturePhotoQuery
    }
    link {
      _linkType
      ... on PRISMIC__ExternalLink {
        url
      }
    }
    publishDate: publish_date
    source
    sourceAuthor: source_author

    source_logo_image
    source_logo_imageSharp {
      ...ThumbnailQuery
    }
    title
    tease
    content
    _meta {
      id
      uid
      type
      tags
    }
  }

  query NewsAndMediaListingQuery($first: Int = 1000, $after: String) {
    prismic {
      stickyPress: allPresss(sortBy: publish_date_DESC, where: { sticky: true }) {
        edges {
          node {
            ...newsAndMediaFields
          }
        }
      }

      allPresss(sortBy: publish_date_DESC, first: $first, after: $after) {
        edges {
          node {
            ...newsAndMediaFields
          }
        }
      }

      pressArchive: allPress_archive_pages {
        edges {
          node {
            seo {
              ...PageSEOQuery
            }
            title
            breadcrumb_title
            header_image
            header_imageSharp {
              ...FeaturePhotoQuery
            }

            cta {
              ...FooterCta
            }
          }
        }
      }
    }
  }
`;

export default NewsAndMediaListing;
